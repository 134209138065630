var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-overlay',[_c('db-searchable-table',{attrs:{"items":_vm.plans,"fields":_vm.fields,"default-sort-key":"isActive","total-rows":_vm.total,"searchable":"","hover":""},on:{"changed":_vm.getData,"row-clicked":_vm.getFile},scopedSlots:_vm._u([{key:"customName",fn:function(ref){
var item = ref.item;
return [(item.docTemplate && item.docTemplate.name)?_c('span',[_vm._v(" "+_vm._s(item.docTemplate.name))]):(item.customName)?_c('span',[_vm._v(" "+_vm._s(item.customName)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"addedBy",fn:function(ref){
var item = ref.item;
return [(item.addedBy && item.addedBy.user && item.addedBy.user.fullname && item.addedBy.user.role)?_c('span',[_c('app-user-info',{attrs:{"variant":"secondary","title":item.addedBy.user.fullname,"alt_title":item.addedBy.user.role.value}})],1):_c('span',[_vm._v(" - ")])]}},{key:"validityStatus",fn:function(ref){
var item = ref.item;
return [(_vm.validityIncludeFields.includes(item.fileGroup.value))?_c('div',[(item.isActive)?_c('span',[_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Geçerli")])],1):_c('span',[_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v("Sonlandırıldı")])],1)]):_c('div',[_vm._v("-")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('app-dropdown',[_c('b-dropdown-item',{on:{"click":function($event){return _vm.getFile(item)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EyeIcon"}}),_c('span',[_vm._v("Görüntüle")])],1)],1)]}}]),model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }